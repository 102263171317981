* {
    -webkit-print-color-adjust: exact;
}
.column.main {
    padding: 0;
}
.block-content {
    display: flex;
    flex-flow: row wrap;
    .box {
        width: 50%;
    }
}
.account .page-title-wrapper.wrapper-content {
    border: none;
    padding: 0;
}
.account .column.main .table-wrapper {
    border: none;
}
.actions-toolbar {
    display: none;
}
.data-table thead {
    display: table-header-group;
}
.data-table tbody th,
.data-table tbody td,
.data-table tfoot th,
.data-table tfoot td {
    display: table-cell;
    padding: 3px 10px;
    &:before {
        display: none;
    }
}
#jvlabelWrap {
    display: none;
}

@import 'additional_print';